import React from 'react';


function MainPage() {
    document.title = 'Dashboard';
    return (
        <div>
            <p>Secure Key public so do not register with your faviroute password</p>
            <p>More features coming future</p>
        </div>
    );
}

export default MainPage;
